import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Button from '@material-ui/core/Button';
import { ChartData } from './PortfolioChart';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import ReactGA from 'react-ga';

export type InputFormProps = {
  formState: {
    currentAge: number;
    retirementAge: number;
    cryptoCurrency: string;
    currentBalance: number;
    monthlyContributions: number;
  };
  update: Function;
}

export default function InputForm(props: InputFormProps) {
  return (
    <Box>
      <Container>
        <Grid container 
          spacing={2}
          direction="column"
        >

          {/* Form Title */}
          <Grid item xs={12}>
            <Typography variant="h6"> 
              Enter Your Information
            </Typography>
          </Grid>

          {/* Current Age */}
          <Grid item xs={12}>
            <TextField 
              id="current-age-basic"
              label="Current Age"
              variant="outlined"
              fullWidth
              placeholder='30'
              type='number'
              value={props.formState.currentAge}
              onChange={(e) => {
                props.update({
                  ...props.formState,
                  currentAge: e.target.value
                });
                ReactGA.event({
                  category: 'cryptoCalculator',
                  action: 'Updated Current Age',
                  value: parseInt(e.target.value),
                });
              }}
            />
          </Grid>

          {/* Retirement Age */}
          <Grid item xs={12}>
            <TextField
              id="retirement-age-basic"
              label="Retirement Age"
              fullWidth
              variant="outlined"
              placeholder='67' 
              type="number" 
              value={props.formState.retirementAge}
              onChange={(e) => {
                props.update({
                  ...props.formState,
                  retirementAge: e.target.value
                });
                ReactGA.event({
                  category: 'cryptoCalculator',
                  action: 'Updated Retirement Age',
                  value: parseInt(e.target.value),
                });
            }}
            />
          </Grid>

          {/* Cryptocurrency */}
          <Grid item xs={12}>
            <TextField
              id="cryptocurrency-basic" 
              label="Cryptocurreny"
              fullWidth
              select
              variant="outlined"
              placeholder="Bitcoin" 
              value={props.formState.cryptoCurrency}
              onChange={(e) => {
                props.update({
                  ...props.formState,
                  cryptoCurrency: e.target.value
                });
                ReactGA.event({
                  category: 'cryptoCalculator',
                  action: 'Updated Cryptocurrency',
                  label: e.target.value
                });
              }}
            >
              {Array.from(cryptoCurrencyROR.keys()).map((key: string) => {
                return (
                  <MenuItem key={key} value={key}>{key}</MenuItem>
                  );
              })}
              </TextField>
          </Grid>

          {/* Current Balance */}
          <Grid item xs={12}>
            <TextField 
              id="cryptocurrency-basic"
              label="Current Balance"
              variant="outlined"
              fullWidth
              placeholder="1000"
              type='number'
              value={props.formState.currentBalance}
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <AttachMoney />
                    </InputAdornment>
                ),
              }}
              onChange={(e) => {
                props.update({
                  ...props.formState,
                  currentBalance: parseInt(e.target.value),
                });
                ReactGA.event({
                  category: 'cryptoCalculator',
                  action: 'Updated Current Balance',
                  value: parseInt(e.target.value),
                });
              }}
            />
          </Grid>

          {/* Monthly Contributions */}
          <Grid item xs={12}>
            <TextField 
              id="cryptocurrency-basic"
              label="Monthly Contributions"
              variant="outlined"
              fullWidth
              type='number'
              value={props.formState.monthlyContributions}
              placeholder="250"
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <AttachMoney />
                    </InputAdornment>
                ),
              }}
              onChange={(e) => {
                props.update({
                  ...props.formState,
                  monthlyContributions: parseInt(e.target.value),
                });
                ReactGA.event({
                  category: 'cryptoCalculator',
                  action: 'Update Monthly Contributions',
                  value: parseInt(e.target.value),
                });
              }}
            />
          </Grid>

          {/* Calculate Button */}
          <Grid item xs={12}>
            <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              const results = calculate({...props.formState});
              props.update({
                ...props.formState,
                chartData: results.data,
                totalContributions: results.totalContributions,
                totalGrowth: results.totalGrowth,
                estimatedBalance: results.estimatedBalance,
                duration: results.duration,
                selectedCurrency: results.selectedCurrency,
                millionaireAge: results.millionaireAge,
                calculated: results.calculated,
                initialContribution: props.formState.currentBalance,
              });
              ReactGA.event({
                category: 'cryptoCalculator',
                action: 'calculate'
              });
            }}
            >
              Calculate
            </Button>
          </Grid>

        </Grid>

      </Container>
      
    </Box>
  );
}

type inputs = {
    currentAge: number;
    retirementAge: number;
    cryptoCurrency: string;
    currentBalance: number;
    monthlyContributions: number;
}
// Use 1.25% of past returns rate
// Mean Annual Return 1466% 2016 - 2020 
const historicalAdjustment = 0.0125
const cryptoCurrencyROR = new Map<string, number>().set("Bitcoin", 1466 * historicalAdjustment / 100);
// Mean Annual Return 2472%% 2017 - 2020
cryptoCurrencyROR.set("Ethereum", 2472 * historicalAdjustment / 100);

const calculate = (props: inputs) => {

  let data: ChartData[] = [
      {
          age: props.currentAge,
          portfolioBalance: props.currentBalance,
          year: new Date().getFullYear(),
          millionaireBar: props.currentBalance > 1000000,
      }
  ];
  let totalContributions: number = 0;
  let totalGrowth: number = 0;
  let estimatedBalance: number = 0;
  let millionaireAge: number = 0;
  const rate = cryptoCurrencyROR.has(props.cryptoCurrency) ? cryptoCurrencyROR.get(props.cryptoCurrency) : .1;

  for(let age = props.currentAge; age < props.retirementAge; age++) {
      const thisYearsBalance = data[data.length - 1].portfolioBalance;
      const newBalance = calculateNextYearsBalance(thisYearsBalance, 1, rate! / 12, 12, props.monthlyContributions);
      if (newBalance > 1000000 && millionaireAge === 0) {
        millionaireAge = age+1
      }
      data.push({
          age: age+1,
          portfolioBalance:  newBalance,
          year: data[data.length - 1].year+1,
          millionaireBar: millionaireAge === age+1,
      });

  }

  const duration = props.retirementAge - props.currentAge
  totalContributions = 12 * duration * props.monthlyContributions;
  estimatedBalance= data[data.length - 1].portfolioBalance;
  totalGrowth = estimatedBalance - props.currentBalance - totalContributions;
  return {
    data,
    totalGrowth,
    estimatedBalance,
    totalContributions,
    duration,
    selectedCurrency: props.cryptoCurrency,
    millionaireAge,
    calculated: true,
  };
}

const calculateNextYearsBalance = (principle: number, time: number, rate: number, num: number, contribution: number) => {

  let balance = principle;
  for (let i : number = 0; i < 12; i++) {
      balance = calculateNextMonthsBalance(balance, rate, contribution);
  }
  return Math.round(balance);
}

const calculateNextMonthsBalance = (principle: number, rate: number, contribution: number) => {
  return principle + (principle * rate) + contribution;
}
