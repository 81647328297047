import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import DefaultPageLayout from './DefaultPageLayout';

export default function AboutPage() {

    return <DefaultPageLayout
        title="A Complete Cryptocurrency Guide"
        htmlTitle="About"
        description="A complete, no-hype cryptocurrency guide for everyone, whether you are beginner, hobbyist or expert."
        body={
            <div>
                <Box pb={4}>
                    <Overview/>
                </Box>
                <Divider variant='middle'/>
                <Box pb={4} pt={4}>
                    <AboutAuthor/>
                </Box>
                <Divider/>
                <Box pb={4} pt={4}>
                    <Contact/>
                </Box>
            </div>
            }
    />
}

const Overview = () => {
    return (
        <div>
            <Typography variant="h4">
                WiseVesting is:
            </Typography>
            <List component="nav" aria-label="main mailbox folders">
                <ListItemText>
                    <Typography variant="h4">
                        &nbsp; &nbsp; - for everyone, whether you are beginner, hobbyist or expert.
                    </Typography>
                </ListItemText>
                <ListItemText>
                    <Typography variant="h4">
                        &nbsp; &nbsp; - unbasis without all of the flashy gurus, promise of instant wealth, and hype.
                    </Typography>
                </ListItemText>
                <ListItemText>
                    <Typography variant="h4">
                        &nbsp; &nbsp; - a set of resources that empower you to confidently
                            make your own decisions.
                    </Typography>
                </ListItemText>
            </List>

        </div>
        );
}

const AboutAuthor = () => {
    return (
        <div>
            <Box pb={1}>
                <Typography variant="h5" paragraph>
                    Creator
                </Typography>
                <Typography paragraph>
                    I am Chris Manning, a personal finance nerd, real estate investor, and software engineer who
                    has a growing passion in cryptocurrencies.
                </Typography> 
                <Typography paragraph>  
                    My interest in cryptocurrencies began in 2012. 
                    I downloaded mining software, researched wallets, analyzing the hashrate of my
                    2008 HP Laptop with Windows 7 but I never took the plunge. The lack of profitability of mining
                    from my laptop and the negative 
                    sentiment towards the long term outlook on future of cryptocurrencies drove me away from actually making an 
                    investment. 
                </Typography> 
                <Typography paragraph>     
                    Fast forward to now, my goal is to build the tools and resources I wish exsisted then.
                    I hope you benefit from them as well!
                </Typography>
            </Box>
        </div>
        );
}

const Contact = () => {
    return (
        <div>
            <Typography variant="h5" paragraph>
                Contact
            </Typography>
            <Typography paragraph>
                If you would like to get in touch, please reach out at wisevesting@gmail.com
            </Typography>
        </div>
    )
}