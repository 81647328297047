import { Typography } from "@material-ui/core"

import DefaultPageLayout from "./DefaultPageLayout";

export const EarningsDisclaimerPage = () => {

    return (
        <DefaultPageLayout
            title="Earnings Disclaimer"
            description="Perform your own due diligence. You’re fully 
            responsible for the investments you make and any outcomes that may result"
            body={<EarningsDisclaimer/>}
        />
    );
    
}

const EarningsDisclaimer = () => {

    return (
        <Typography >
            You should perform your own due diligence and use your own best judgment prior to making any investment decision.
            By virtue of visiting this site or interacting with any portion of this site, you agree that you’re fully 
            responsible for the investments you make and any outcomes that may result.
        </Typography>
    );
}