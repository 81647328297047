
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { ABOUT_PAGE_URL, EARNINGS_DISCLAIMER_PAGE_URL, ROOT_PAGE_URL } from './URLs';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
});

type FooterProps = {
  fixFooter?: boolean;
}

export default function Footer(props: FooterProps) {

    const theme = useTheme();
    const classes = useStyles();

    return <footer>
        <Box bgcolor={theme.palette.primary.main} className={props.fixFooter ? classes.root : ""} pt={2}>
            <Container maxWidth="lg" >
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={8}>
                        <Box>
                            <Typography variant="h5" color='secondary'>
                              <IconButton edge="start" aria-label="menu" href={ROOT_PAGE_URL} color='secondary'>
                                <TrendingUpIcon fontSize="large"/>
                              </IconButton>
                              WiseVesting
                            </Typography>
                        </Box>
                    </Grid>  
                    <Grid item xs={12} sm={4}>
                      <Box>
                          <Link href={ABOUT_PAGE_URL} color="textPrimary">
                            About
                          </Link>
                      </Box>
                      <Box>
                        <Link href={EARNINGS_DISCLAIMER_PAGE_URL} color="textPrimary">
                            Earnings Disclaimer
                        </Link>
                      </Box>
                    </Grid>  
                </Grid>
            </Container >
        </Box>
    </footer>
}