import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

const host = window.location.hostname;
if(host !== "localhost") {
  ReactGA.initialize('UA-204552075-1');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

type sendAnalyticsProp = {
  id: string;
  name: string;
  value: number;
}

function sendToAnalytics(props: sendAnalyticsProp) {
  const host = window.location.hostname;
  if(host !== "localhost") {
    ReactGA.event({
      category: 'Web Vitals',
      action: props.name,
      value: Math.round(props.name === 'CLS' ? props.value * 1000 : props.value), // values must be integers
      label: props.id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate
    });
  }
}

reportWebVitals(sendToAnalytics);
