import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { numberWithCommas } from './PortfolioChart';

type ResultsSummaryProps = {
    estimatedBalance: number,
    estimatedGrowth: number,
    totalContributions: number,
    initialContribution: number,
    duration?: number,
    selectedCurrency?: string,
    millionaireAge?: number,
    display: boolean,
}

export default function ResultsSummary(props: ResultsSummaryProps) {
    return (
        <Container>
            <Grid container
                spacing={2}
            >
                {/* Summary */}
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Estimated {props.display ? props.selectedCurrency : 'Crypto'} Balance: {props.display ? BoldNumbers({value: props.estimatedBalance}) : 0}
                    </Typography>
                </Grid>

                {/* Balance Breakdown */}
                <Grid item xs={12}>
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs>
                            Initial Balance: {props.display ? BoldNumbers({value: props.initialContribution}) : 0}
                        </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem/>
                        <Grid item xs>
                            Total Contributions: {props.display ? BoldNumbers({value: props.totalContributions}) : 0}
                        </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem/>
                        <Grid item xs>
                            Estimated Growth: {props.display ? BoldNumbers({value: props.estimatedGrowth}) : 0}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </Container>
    );
}

type BoldNumbersProps = {
    value: number;
    display?: string;
}

const BoldNumbers = (props: BoldNumbersProps) => {
    return <Box fontWeight="fontWeightBold" display={props.display}>
        {numberWithCommas(props.value)}
    </Box>
}