import { useTheme } from '@material-ui/core';
import React from 'react';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell
  } from "recharts";

export type PortfolioChartProps = {
    data: ChartData[];
};

export type ChartData = {
    age: number;
    portfolioBalance: number;
    year: number;
    millionaireBar: boolean;
}


export default function PortfolioChart(props: PortfolioChartProps) {

    const theme = useTheme();

    function CustomTooltip({ payload, label, active } : any) {
        if (active) {
            return (
                <div className="custom-tooltip">
                <p className="label">{`${label}: ${numberWithCommas(payload[0].value)}`}</p>
                </div>
            );
        }
    
        return null;
    }

    function NumberFormattor(number: number) {
        if(number > 1000000000) {
            return `$${(number/1000000000).toString()}B` ;
          }else if(number > 1000000) {
            return `$${(number/1000000).toString()}M`;
          }else if(number > 1000) {
            return `$${(number/1000).toString()}K`;
          }else{
            return `$${number.toString()}`;
          }
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 25,
                    left: 25,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" color={theme.palette.grey[400]}/>
                <XAxis dataKey="year" color={theme.palette.grey[400]}/>
                <YAxis tickFormatter={NumberFormattor} color={theme.palette.grey[400]}/>
                <Tooltip content={<CustomTooltip />}/>
                <Bar dataKey="portfolioBalance">
                    {props.data.map((entry, _) => (
                        <Cell fill={entry.millionaireBar ? theme.palette.secondary.dark : theme.palette.secondary.light }/>
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

export function numberWithCommas(x: number) {
    return `$` + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

