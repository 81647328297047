import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React from 'react';
import { BLOGS_PAGE_URL, CRYPTO_INVESTMENT_CALCULATOR_PAGE_URL, ROOT_PAGE_URL } from './URLs';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    title: {
      flexGrow: 1,
    },
    navIconHide: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuItems: {
      color: theme.palette.secondary.main,
    }
  }),
);

export default function CalculatorAppBar() {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start"
                      aria-label="menu"
                      href={ROOT_PAGE_URL}
                      color='secondary'
                    >
                      <TrendingUpIcon fontSize="large"/>
                    </IconButton>
                    <Typography variant="h5" color='secondary' className={classes.title}>
                        WiseVesting
                    </Typography>
                    <Button color="secondary" 
                      className={classes.menuButton} 
                      href={CRYPTO_INVESTMENT_CALCULATOR_PAGE_URL}
                    >
                      Crypto Investment Calculator
                    </Button>
                    <Button color="secondary"
                     className={classes.menuButton}
                     href={BLOGS_PAGE_URL}
                    >
                      Blog
                    </Button>
                    <Hidden mdUp>
                      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                        <MenuIcon color="secondary"/>
                      </Button>
                      <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                      >
                        <MenuItem href={CRYPTO_INVESTMENT_CALCULATOR_PAGE_URL} component="a" className={classes.menuItems}> 
                          Crypto Investment Calculator
                        </MenuItem>
                        <MenuItem href={BLOGS_PAGE_URL} component="a" className={classes.menuItems}> 
                          Blog
                        </MenuItem>
                      </Menu>
                    </Hidden>
                    {/* <Button color="secondary" variant="outlined">Get Started</Button> */}
                </Toolbar>
            </AppBar>
        </div>
    )
}
