// URL Definitions. This ensures consistency throughout the project.
export const ABOUT_PAGE_URL = `/about`;
export const ROOT_PAGE_URL = `/`;
export const CONTACT_PAGE_URL = `/`;
export const EARNINGS_DISCLAIMER_PAGE_URL = '/earnings-disclaimer';
export const CRYPTO_INVESTMENT_CALCULATOR_PAGE_URL = '/crypto-investment-calculator';
export const BLOGS_PAGE_URL='/blogs';
export const BLOG_PAGE_URL='/blog';
export const INTRODUCTION_TO_CRPYTO_CURRENECY='/blog/an-introductin-to-cryptocurrencies';
export const HOW_TO_USE_CRYPTO_INVESTMENT_CALCULATOR_GUIDE='/blog/how-to-use-the-crypto-investment-calculator-guide';
// This is not a dumping ground for variables!