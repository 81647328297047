import React from 'react';
import { TitlelessPage } from "./TitlelessPage"


export const ErrorPage = () => {
    return (
        <TitlelessPage
          body={<p>
                Oops, something went wrong! Refresh the page to reload.
                If the problem persists check back in later.
               </p>}
          fixFooter
        />
    )
}