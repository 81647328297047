import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import CalculatorPage from './components/CalculaterPage';
import AboutPage from './components/AboutPage';
import { ABOUT_PAGE_URL, BLOGS_PAGE_URL, BLOG_PAGE_URL, CRYPTO_INVESTMENT_CALCULATOR_PAGE_URL, EARNINGS_DISCLAIMER_PAGE_URL, ROOT_PAGE_URL } from './components/URLs';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'; 
import { EarningsDisclaimerPage } from './components/EarningsDisclaimerPage';
import { AggregateBlogsPage } from './components/AggregateBlogsPage';
import { IndividualBlogPage } from './components/IndividualBlogPage';
import CalculatorAppBar from './components/CalculatorAppBar';
import Footer from './components/Footer';


let theme = createTheme({
  palette: {
    primary: {
      main: '#f5f5f5',
      light: '#ffffff',
      dark: '#c2c2c2',
      contrastText: '#000000',
    },
    secondary: {
      main: '#1b5e20',
      light: '#4c8c4a',
      dark: '#003300',
      contrastText: '#ffffff',
    },
  }
})

theme = responsiveFontSizes(theme);

function App() {

  return (

    <ThemeProvider theme={theme}>
      <CalculatorAppBar/>
      <div className="main-container">
        <Router>
            <Switch>
              <Route path={ABOUT_PAGE_URL}>
                <AboutPage/>
              </Route>
              <Route path={EARNINGS_DISCLAIMER_PAGE_URL}>
                <EarningsDisclaimerPage/>
              </Route>
              <Route path={CRYPTO_INVESTMENT_CALCULATOR_PAGE_URL}>
                <Redirect to={ROOT_PAGE_URL} />
              </Route>
              <Route path={BLOGS_PAGE_URL}>
                <AggregateBlogsPage/>
              </Route>
              <Route path={BLOG_PAGE_URL + `/:urlPath`}>
                <IndividualBlogPage/>
              </Route>
              <Route path={ROOT_PAGE_URL}>
                <CalculatorPage/>
              </Route>
            </Switch>
        </Router>
      </div>
      <footer>
        <Footer/>
      </footer>
    </ThemeProvider>
  );
}

export default App;
