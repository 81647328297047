import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Link from '@material-ui/core/Link';
import { BLOG_PAGE_URL } from './URLs';
import { useCallback } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import DefaultPageLayout from './DefaultPageLayout';
import ReactGA from 'react-ga';

export const AggregateBlogsPage = () => {

    return (
        <DefaultPageLayout
            title="Blog"
            description="A collection of articles about strategies, benefits and risks to long-term cryptocurrency investing"
            body={
                <AggregateBlogs/>
            }
        />
    )
}

export const AggregateBlogs = () => {

  const [error, setError] = useState();
  const [articles, setArticles] = useState(null);

  const fetchData = useCallback(async() => {

    try {
        const response = await axios.post('https://618tht2nk3.execute-api.us-east-1.amazonaws.com/prod/blogs', {
            'additionalQueryParams': 'content_type=blog&order=-sys.createdAt'
        });
        setArticles(response.data.items as any);
    } catch (error) {
        setError(error);
    }
  }, []);

  useEffect(() => {fetchData();}, [fetchData]);

  if (error) {
    ReactGA.event({
        category: 'blog',
        action: 'fetchArticles',
        label: error
      });
    return <p>
            Oops, something went wrong! Refresh the page to reload.
            If the problem persists check back in later.
        </p>
  }

  // show a loading screen case the data hasn't arrived yet
  if (!articles) {
    return <CircularProgress color='secondary'/>
  }

  return <BlogEntry articles={articles!}/>
}

type Article = {
    fields: {
        title: string;
        urlPath: string;
        summary: string;
    };
    sys: {
        updatedAt: string;
        id: string;
    }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      paddingBottom: 16,
    }
  }),
);

export type BlogEntryProps = {
    articles: Article[]
}

const BlogEntry = (props: BlogEntryProps) => {

  const classes = useStyles();

    const buildBlogLink = (path: string) => {
        return `${BLOG_PAGE_URL}/${path}`;
    }

    return (
        <List>
            {Array.from(props.articles).map((entry: Article) => {
                const link = buildBlogLink(entry.fields.urlPath);
                return (
                    <ListItemText key={entry.sys.id} className={classes.item}
                        primary={
                            <Typography variant="h5">
                                <Link href={link} color="inherit">  
                                    {entry.fields.title}
                                </Link>
                            </Typography>
                        }
                        secondary={
                            <div>
                                <Typography variant="subtitle1" >
                                        {entry.fields.summary}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                        Last Updated: {convertDate(entry.sys.updatedAt)}
                                </Typography>
                                <Button href={link} color="secondary" variant="outlined" size="small">
                                    Read More
                                </Button>
                            </div>
                        }
                    />
                )
            })}
        </List>
    );
}

export const convertDate = (date: string) => {
    const dateObject = new Date(date);
    return `${dateObject.getDate()} ${dateObject.toLocaleString('default', { month: 'long' })} ${dateObject.getFullYear()}`;
}
