import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputForm from './InputForm';
import PortfolioChart, { ChartData } from './PortfolioChart';
import { useState } from 'react';
import ResultsSummary from './ResultsSummary';
import Typography from '@material-ui/core/Typography';
import DefaultPageLayout from './DefaultPageLayout';
import Link from '@material-ui/core/Link';
import { HOW_TO_USE_CRYPTO_INVESTMENT_CALCULATOR_GUIDE, INTRODUCTION_TO_CRPYTO_CURRENECY } from './URLs';

export default function CalculaterPage() {

    return (
        <div>
            <DefaultPageLayout 
                title="Crypto Investment Calculator"
                description="A simple investment calculator for cryptocurrencies. This calculator  provides an estimated return on your long-term crypto investment."
                body={
                    <div>
                        <Calculator/>
                        <CalculatorContext/>
                    </div>
                }
            />
        </div>
    );
}

const Calculator = () => {
    
    type calculatorState = {
        // user provided data
        currentAge: number;
        retirementAge: number;
        cryptoCurrency: string;
        currentBalance: number;
        monthlyContributions: number;
        // results Data
        chartData?: ChartData[];
        totalContributions: number;
        initialContribution: number;
        totalGrowth: number;
        estimatedBalance: number;
        duration?: number;
        selectedCurrency?: string;
        millionaireAge?: number;
        calculated: boolean;
    }

    const [state, setCalculatorState] = useState<calculatorState>({
        currentAge: 30,
        retirementAge: 50,
        cryptoCurrency: 'Bitcoin',
        currentBalance: 1000,
        monthlyContributions: 250,
        calculated: false,
        estimatedBalance: 0,
        totalContributions: 0,
        totalGrowth: 0,
        initialContribution: 0,
    });

    return (
        <div>
            <Grid container
                spacing={8}
            >
                <Grid item xs={12} sm ={4} md={4}>
                    <Grid container
                        direction="column"
                        justifyContent="flex-start"
                    >
                        <InputForm 
                            formState={state}
                            update={setCalculatorState}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <Grid container
                        direction="column"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <ResultsSummary 
                                estimatedBalance={state.estimatedBalance}
                                estimatedGrowth={state.totalGrowth}
                                totalContributions={state.totalContributions}
                                initialContribution={state.initialContribution}
                                duration={state.duration}
                                selectedCurrency={state.selectedCurrency}
                                millionaireAge={state.millionaireAge}
                                display={state.calculated}

                            />
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        {state.calculated && 
                            <Grid item xs={12} style = {{minWidth: "100%"}}>
                                <PortfolioChart
                                    data={state.chartData!}
                                />  
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid> 
        </div>
    );
}

const CalculatorContext = () => {
    
    return (
        <div> 
            <Grid container 
                direction="column"
                spacing={8}
            >
                <Grid item>
                    <Typography variant="h5" paragraph>
                        How does this work?
                    </Typography>
                    <Typography >
                        This is a simple investment calculator. It uses a reduced historical monthly average growth rate 
                        for the selected cryptocurrency to estimate your balance for each month. These estimates are then aggregated
                        together to project an estimated balance over the duration of your investment. These results purely hypothetical
                        estimation of your potential balance. For detailed look walk through checkout 
                        the <Link href={HOW_TO_USE_CRYPTO_INVESTMENT_CALCULATOR_GUIDE} color='secondary'>
                        How to use the Crypto Investment Calculator Guide
                        </Link>.
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="h5" paragraph>
                        What are cryptocurrencies?
                    </Typography>
                    <Typography paragraph>
                        These are a form of digital currency which are secured by cryptography. Cryptography currencies became
                        cryptocurrencies. Using cryptography, the study of secure communications, makes cryptocurrencies nearly
                        impossible to counterfeit and inherently more secure than non-digital currencies. 
                    </Typography>
                    <Typography >
                        At this time the calculator supports two types of cryptocurrencies: Bitcoin and Ethereum. These two 
                        cryptocurrencies can be viewed as the blue chips of cryptocurrencies. They are well known, have a lot
                        of support, and have brighter long term horizon than other cryptocurrencies. 
                        To learn more, check out our <Link href={INTRODUCTION_TO_CRPYTO_CURRENECY} color='secondary'>
                            Introduction to Cryptocurrencies Guide
                          </Link>.
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="h5" paragraph>
                        What is the reduced historical monthly average growth rate?
                    </Typography>
                    <Typography paragraph>
                        Projecting growth rates is very complex especially given the short amount of historical 
                        data available, deflationary properties of certain cryptocurrencies, and the extreme pricing
                         volatility that comes
                        with this sector. The current growth rates for Bitcoin and Ethereum based on the last five
                        years of historical data are 1466% and 2472% respectively as of August 2021.
                        These growth rate are unsustainable at the current pace. This calculator use 1.25% of the
                        last five years of historical averages. That's very conservative with a reduction of 98.75% of 
                        the current growth rate!
                        </Typography>
                        <Typography >
                        For Bitcoin this calculator uses a growth rate of 18.325% (5 year historical average - 1466% * 1.25%).
                        For Ethereum this calculator uses a growth rate of 30.9% (5 year historical average - 2472% * 1.25%).  
                    </Typography>
                </Grid>
            </Grid> 
        </div>
    );
}
