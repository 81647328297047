import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

export type DefaultPageLayoutProps = {
    title: string,
    htmlTitle?: string,
    description?: string,
    body: JSX.Element,
    fixFooter?: boolean,
};

export default function DefaultPageLayout(props: DefaultPageLayoutProps) {

    return (
        <div>
            <Helmet>
                <title>{`${props.htmlTitle ? props.htmlTitle : props.title}`}</title>
                <meta name="description" content={props.description} />
            </Helmet>

            <Box m={4}>                
                <Container>
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        spacing={4}
                    >
                        <Grid item xs={12} sm={11} lg={10} md={10} >
                            <Typography variant="h2">
                                {props.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={11} lg={10} md={10} >
                            {props.body}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
}