import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useCallback } from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DefaultPageLayout from './DefaultPageLayout';
import { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import { ErrorPage } from './ErrorPage';
import { TitlelessPage } from './TitlelessPage';
import Box from '@material-ui/core/Box';
import { convertDate } from './AggregateBlogsPage';
import ReactGA from 'react-ga';

type paramsType = {
    urlPath: string
}

type Article = {
    fields: {
        title: string;
        urlPath: string;
        body:  Document;
        summary: string;
        author: string;
    };
    metadata: {
        tags: string[];
    };
    sys: {
        createdAt: string;
        updatedAt: string;
    }
}

type Asset = {
    fields: {
        title: string;
        description: string;
        file:  {
            url: string;
            details: {
                image: {
                    height: number;
                    width: number;
                };
            }
        };
    };
    metadata: {
        tags: string[];
    };
    sys: {
        createdAt: string;
        id: string;
    }
}

export const IndividualBlogPage = () => {

    const params: paramsType = useParams();

    const [error, setError] = useState();  
    const [article, setArticle] = useState<Article|undefined>(undefined);
    const [assets, setAssets] = useState<Asset[]>([]);
  
    const fetchData = useCallback(async() => {
        try {
            const response = await axios.post('https://618tht2nk3.execute-api.us-east-1.amazonaws.com/prod/blogs', {
                'additionalQueryParams': `content_type=blog&fields.urlPath=${params.urlPath}&include=10&limit=1`
            });
            setAssets(response.data.includes?.Asset);
            setArticle(response.data.items[0] as any);
        } catch (error) {
            setError(error);
        }

    }, [params.urlPath]);
  
    useEffect(() => {fetchData();}, [fetchData]);
  
    if (error) {
        ReactGA.event({
            category: 'blog',
            action: 'fetchArticles',
            label: error
          });
        return (<ErrorPage/>);
      }

    if (article === undefined) {
      return <TitlelessPage
        body={<CircularProgress color='secondary'/>}
      />
    }
  
    return (
        <DefaultPageLayout 
            title={article.fields.title}
            description={article.fields.summary}
            body={<BlogEntry article={article} assets={assets}/>}
        />
    );
}

type BlogEntryProps = {
    article: Article;
    assets: Asset[];
}

const BlogEntry = (props: BlogEntryProps) => {

    const richTextOptions = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const asset = props.assets.find(i => i.sys.id === node.data.target.sys.id)
            return (
              <img style= {{width: "100%", maxWidth: `${asset?.fields.file.details.image.width}px`}}
                src={asset?.fields.file.url}
                alt={asset?.fields.title}
              />
          )}
        }
      }
      
    return (
        <div>
            <Box pb={2}>
            <Typography variant="subtitle2" >
                Published: {convertDate(props.article.sys.createdAt)} by {props.article.fields.author}
            </Typography>
            </Box>
            {documentToReactComponents(props.article.fields.body, richTextOptions)}
            <Box>
            <Typography variant="subtitle2">
                Last Updated: {convertDate(props.article.sys.updatedAt)}
            </Typography>
            </Box>

        </div>
    );
}
