import React from 'react';
import DefaultPageLayout from './DefaultPageLayout';

export type TitlelessPageProps = {
    body: JSX.Element,
    fixFooter?: boolean,
};

export const TitlelessPage = (props: TitlelessPageProps) => {
    return (
        <div>
            <DefaultPageLayout 
                    title=""
                    body={props.body}
                />
        </div>  
    );
}